<template>
  <div
    ref="rootElement"
    :class="[
      'select',
      `select--is-${variant}`,
      { 'select--is-disabled': disabled },
      { active: modelValue || isDropdownOpen },
    ]"
    @click="toggleDropdown"
  >
    <div
      :class="['select-input']"
      tabindex="0"
    >
      <slot
        name="item"
        :item="modelValue"
      >
        <div
          :class="[
            'select-label',
            { 'select-label--is-placeholder': !modelValue },
          ]"
        >
          <div v-if="!modelValue">
            <span>{{ placeholder }}</span>
          </div>
          <div v-if="!multiselect && modelValue">
            <span v-if="modelValue">{{ selectionLabel }}</span>
          </div>
          <div v-if="multiselect && modelValue">
            <div v-if="modelValue.length && modelValue.length === options.length">
              <span>{{ placeholder ? `${placeholder}: All` : 'All' }} </span>
            </div>
            <div v-else>
              <span>{{ selectionLabel }}</span>
              <span
                class="additional-selections"
                v-if="modelValue.length > 1"
              >{{ `+${modelValue.length - 1}` }}</span>
            </div>
          </div>
        </div>
      </slot>

      <Icon :name="chevronIcon" />
    </div>

    <ul
      v-show="options.length && isDropdownOpen"
      :class="[
        'select-dropdown',
        { 'select-dropdown--upwards': upwards },
        { 'select-dropdown--downards': !upwards },
        { 'select-dropdown--left': !right },
        { 'select-dropdown--right': right },
      ]"
    >
      <li
        v-for="(option, index) in filterOptions"
        :key="index"
        class="select-dropdown-item"
        @click="updateSelection(option)"
      >
        <Checkbox
          v-if="variant !== 'secondary'"
          :model-value="option.selected"
          @update:model-value="() => updateSelection(option)"
          :intermediate="false"
          :round="!multiselect"
        />
        <span class="label">{{ option?.label }}</span>
        <Icon
          v-if="variant === 'secondary' && option.selected"
          name="check"
        />
      </li>
      <li
        v-if="showFooterAction"
        class="select-dropdown-item select-dropdown-item--footer"
        :class="{ 'select-dropdown-item--disabled': footerActionDisabled }"
        @click="onFooterAction"
      >
        {{ footerActionText }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import {
  map,
  find,
  includes,
  isArray,
  toLower,
  filter,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Icon,
    Checkbox,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator(value) {
        return includes(['primary', 'secondary', 'terciary'], value);
      },
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    upwards: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    omitPrefix: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFooterAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const filterOptions = computed(() => map(props.options, (item) => ({
      ...item,
      selected: props.multiselect ? includes(props.modelValue, item.value) : toLower(props.modelValue) === toLower(item.value),
    })));
    const isDropdownOpen = ref(false);
    const labelByValue = (value) => find(filterOptions.value, { value })?.label || 'N/A';
    const toggleDropdown = () => {
      if (props.disabled) {
        isDropdownOpen.value = false;
        return;
      }
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const rootElement = ref(null);
    onClickOutside(rootElement, () => {
      isDropdownOpen.value = false;
    });

    const updateSelection = (item) => {
      if (props.multiselect) {
        const updated = find(filterOptions.value, { value: item.value });
        updated.selected = !updated.selected;
      } else {
        filterOptions.value.forEach((option) => {
          if (item.value === option.value) return;
          // eslint-disable-next-line no-param-reassign
          option.selected = false;
        });
      }
      emit('update:modelValue', props.multiselect ? item.value : item.value);
    };

    const chevronIcon = computed(() => {
      if (props.modelValue === 'primary') return (isDropdownOpen.value ? 'chevron-up' : 'chevron-down');
      return 'selector-vertical';
    });
    const selectionLabel = computed(() => {
      const value = (isArray(props.modelValue) ? props.modelValue[0] : props.modelValue);
      const baseLabel = labelByValue(value);
      if (props.omitPrefix) return baseLabel;
      return props.placeholder ? `${props.placeholder}: ${baseLabel}` : baseLabel;
    });

    const selectedItemsLength = computed(() => filter(filterOptions.value, { selected: true }).length);
    const footerActionText = computed(() => {
      if (props.multiselect) {
        if (selectedItemsLength.value === filterOptions.value.length) return 'Deselect all';
        return 'Select all';
      }
      return 'Clear';
    });
    const footerActionDisabled = computed(() => !selectedItemsLength.value && !props.multiselect);
    const onFooterAction = () => {
      let filterOptionsCopy = [...filterOptions.value];
      if (props.multiselect) {
        filterOptionsCopy = map(filterOptionsCopy, (option) => ({
          ...option,
          selected: selectedItemsLength.value !== filterOptions.value.length,
        }));
      }
      emit('update:modelValue', props.multiselect && selectedItemsLength.value !== filterOptions.value.length
        ? map(filter(filterOptionsCopy, { selected: true }), 'value')
        : null);
    };

    return {
      rootElement,
      isDropdownOpen,
      filterOptions,
      toggleDropdown,
      updateSelection,
      labelByValue,
      chevronIcon,
      selectionLabel,
      footerActionText,
      footerActionDisabled,
      onFooterAction,
    };
  },
};
</script>

<style lang="scss">
.select {
  position: relative;
  height: 100%;

  .select-input {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $rowsPerPageDropdownButtonBackgroundColor;
    border: $rowsPerPageDropdownButtonBorder;
    border-radius: $rowsPerPageDropdownButtonBorderRadius;
    padding: 6px 12px;
    cursor: pointer;
    transition: $rowsPerPageDropdownButtonTransition;
    user-select: none;
    stroke: $black;

    & .select-label {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::placeholder {
      color: $textInputPlaceholderColor;
      user-select: none;
    }

    &:focus {
      box-shadow: $textInputFocusBoxShadow;
      outline: none;
    }

    & .additional-selections {
      display: inline-block;
      font-size: 14px;
      color: white;
      background-color: $brandPrimary500;
      padding: 2px 6px;
      border-radius: 22px;
      margin-left: 4px;
    }
  }

  &.active .select-input {
    background: #E5EBEB;
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    background-color: $rowsPerPageDropdownContentBackgroundColor;
    box-shadow: $rowsPerPageDropdownContentBoxShadow;
    border-radius: $rowsPerPageDropdownContentBorderRadius;
    margin-bottom: $rowsPerPageDropdownContentMarginBottom;
    border: $rowsPerPageDropdownContentBorder;
    min-width: 100%;
    user-select: none;
    z-index: $dropdownMenuZIndex;

    &.select-dropdown--upwards {
      bottom: 100%;
    }

    &.select-dropdown--downwards {
      top: 100%;
    }

    &.select-dropdown--left {
      left: 0;
    }

    &.select-dropdown--right {
      right: 0;
    }
  }

  .select-dropdown-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: $rowsPerPageDropdownItemBackgroundColor;
    padding: 9.5px 12px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $rowsPerPageDropdownItemHoverBackgroundColor;
    }

    & .label {
      margin-left: 8px;
    }
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}

.select.select--is-secondary {
  .select-label {
    &--is-placeholder {
      color: #CDCDCD;
    }
  }

  .select-input {
    background: #fff;
    padding: 0 8px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    &::placeholder {
      color: #CDCDCD;
    }

    &:focus {
      background: #fff;
      box-shadow: none;
      outline: 1px solid #a2bfff;
    }
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% - 4px);
    right: 0;
    width: 150px;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    padding: 4px 0;
    user-select: none;

    .select-dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: pointer;

      .label {
        margin: 0;
      }

      .icon {
        svg {
          path {
            stroke: #191414;
          }
        }
      }

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }
      &--footer {
      border-top: 1px solid $gray400;
      color: $error500;
      }

      &--disabled {
        cursor: not-allowed;
        color: $gray700;
      }
    }
  }
}

.select.select--is-terciary {
  .select-label {
    &--is-placeholder {
      color: #CDCDCD;
    }
  }

  .select-input {
    background: #fff;
    padding: 0 8px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    &::placeholder {
      color: #CDCDCD;
    }

    &:focus {
      background: #fff;
      box-shadow: none;
      outline: 1px solid #a2bfff;
    }

    & .additional-selections {
      font-size: 10px;
      padding: 2px;
      border-radius: 2px;
      margin-left: 4px;
      height: 16px;
      width: 16px;
      font-weight: 400;
      position: relative;
      bottom: 1px;
    }
  }
}

.select {
  &--is-disabled {
    .select-input {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
