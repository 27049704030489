<template>
  <div class="odds-checker-table-row-cell">
    <div
      :class="['cell-state-icon', { 'live': event.matchState === 'LIVE' }]"
      v-if="column.type === 'label-icon'"
    >
      <Icon name="signal" />
    </div>
    <div
      class="odds-checker-cell-label"
      v-if="column.type !== 'market'"
    >
      <div
        class="odds-checker-cell-label-row"
        v-if="cellValue.topRow"
      >
        {{ cellValue.topRow }}
      </div>
      <div
        :class="['odds-checker-cell-label-row', {'player-props': column.id === 'playerName'}]"
        v-if="cellValue.bottomRow"
      >
        {{ cellValue.bottomRow }}
      </div>
    </div>
    <div
      v-if="column.type === 'market'"
      class="column-market-half left"
    >
      <div
        class="column-market-suspended"
        v-if="cellValue.huddleMarket?.offeringStatus === 'SUSPENDED'"
      >
        Suspended
      </div>
      <div
        class="column-market-half-selections indicator"
        v-if="cellValue.huddleMarket?.selections[0]?.price?.changedPrice || cellValue.huddleMarket?.selections[1]?.price?.changedPrice"
      >
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.huddleMarket?.selections[0]?.price?.changedPrice === 'up'"
        >
          <Icon name="vector-up" />
        </div>
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.huddleMarket?.selections[0]?.price?.changedPrice === 'down'"
        >
          <Icon name="vector-down" />
        </div>
        <div
          class="empty-indicator"
          v-if="!cellValue.huddleMarket?.selections[0]?.price?.changedPrice"
        />
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.huddleMarket?.selections[1]?.price?.changedPrice === 'up'"
        >
          <Icon name="vector-up" />
        </div>
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.huddleMarket?.selections[1]?.price?.changedPrice === 'down'"
        >
          <Icon name="vector-down" />
        </div>
        <div
          class="empty-indicator"
          v-if="!cellValue.huddleMarket?.selections[1]?.price?.changedPrice"
        />
      </div>
      <div
        :class="['column-market-half-selections line', {'handicap': column.id === 'HANDICAP'}]"
        v-if="displayLine(cellValue.huddleMarket?.selections[0], 'huddleMarket')"
      >
        <div class="column-market-half-selection">
          {{ displayLine(cellValue.huddleMarket?.selections[0], 'huddleMarket') }}
        </div>
        <div class="column-market-half-selection">
          {{ displayLine(cellValue.huddleMarket?.selections[1], 'huddleMarket') }}
        </div>
      </div>
      <div class="column-market-half-selections">
        <div class="column-market-half-selection">
          {{ odd(cellValue.huddleMarket?.selections[0]?.price) }}
        </div>
        <div class="column-market-half-selection">
          {{ odd(cellValue.huddleMarket?.selections[1]?.price) }}
        </div>
      </div>
    </div>
    <div
      v-if="column.type === 'market'"
      class="column-market-half right"
    >
      <div
        class="column-market-suspended"
        v-if="cellValue.bet365Market?.offeringStatus === 'SUSPENDED'"
      >
        Suspended
      </div>
      <div
        class="column-market-half-selections indicator"
        v-if="cellValue.bet365Market?.selections[0]?.price?.changedPrice || cellValue.bet365Market?.selections[1]?.price?.changedPrice"
      >
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.bet365Market?.selections[0]?.price?.changedPrice === 'up'"
        >
          <Icon name="vector-up" />
        </div>
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.bet365Market?.selections[0]?.price?.changedPrice === 'down'"
        >
          <Icon name="vector-down" />
        </div>
        <div
          class="empty-indicator"
          v-if="!cellValue.bet365Market?.selections[0]?.price?.changedPrice"
        />
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.bet365Market?.selections[1]?.price?.changedPrice === 'up'"
        >
          <Icon name="vector-up" />
        </div>
        <div
          class="column-market-half-selection-icon"
          v-if="cellValue.bet365Market?.selections[1]?.price?.changedPrice === 'down'"
        >
          <Icon name="vector-down" />
        </div>
        <div
          class="empty-indicator"
          v-if="!cellValue.bet365Market?.selections[1]?.price?.changedPrice"
        />
      </div>
      <div
        :class="['column-market-half-selections line', {'handicap': column.id === 'HANDICAP'}]"
        v-if="cellValue?.bet365Market && displayLine(cellValue.bet365Market?.selections[0], 'bet365Market')"
      >
        <div class="column-market-half-selection">
          {{ displayLine(cellValue.bet365Market?.selections[0], 'bet365Market') }}
        </div>
        <div class="column-market-half-selection">
          {{ displayLine(cellValue.bet365Market?.selections[1], 'bet365Market') }}
        </div>
      </div>
      <div class="column-market-half-selections">
        <div class="column-market-half-selection">
          {{ odd(cellValue.bet365Market?.selections[0]?.price) }}
        </div>
        <div class="column-market-half-selection">
          {{ odd(cellValue.bet365Market?.selections[1]?.price) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  split, filter, includes, find, isNil,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import Icon from '@/components/common/Icon';
import MarketMapper from '@/services/helpers/market-mapper';
import OddsConverter from '@/services/helpers/odds-converter';

export default {
  components: {
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const cellValue = computed(() => {
      if (props.column.id === 'startsAt') {
        const time = props.event?.startsAt;
        const formatDate = format(zonedTimeToUtc(time, 'UTC'), 'MMM d');
        const formatTime = format(zonedTimeToUtc(time, 'UTC'), 'H:mm a');
        return {
          topRow: formatDate,
          bottomRow: formatTime,
        };
      }
      if (props.column.id === 'eventName') {
        const eventNameSplit = split(props.event?.eventName, ' v ');
        return props.event?.isUsaView
          ? {
            topRow: `${eventNameSplit[1]} @`,
            bottomRow: eventNameSplit[0],
          }
          : {
            topRow: `${eventNameSplit[0]}`,
            bottomRow: eventNameSplit[1],
          };
      }

      if (props.column.id === 'playerName') {
        const homeTeamShortName = props.event?.competitors?.[0]?.shortName;
        const awayTeamShortName = props.event?.competitors?.[1]?.shortName;
        const eventName = props.event?.isUsaView
          ? `${awayTeamShortName} @ ${homeTeamShortName}`
          : `${awayTeamShortName} @ ${homeTeamShortName}`;
        return {
          topRow: props.event.playerName,
          bottomRow: eventName,
        };
      }
      // If column is market
      const columnMarkets = filter(props.event?.markets?.nodes,
        (propMarket) => includes(propMarket.marketCode, props.column.id) && propMarket.marketType.params.SELECTIONS === 2
        && (props.event?.matchState !== 'LIVE' || (props.event?.matchState === 'LIVE' && propMarket.inPlay)));
      const huddleMarket = find(columnMarkets, (market) => (market.source === 'INTERNAL_AGGREGATION'
        && (props.column.id === 'RESULT' || (props.column.id !== 'RESULT' && market.marketSummary?.isMainLine))));
      const bet365Market = find(columnMarkets, (market) => ((market.source === 'BET_365')
        && (props.column.id === 'RESULT' || (props.column.id !== 'RESULT' && market.marketSummary?.isMainLine))));
      return {
        huddleMarket: huddleMarket
          ? {
            ...huddleMarket,
            selections: MarketMapper.sortSelections({ selections: huddleMarket?.selections, isUSAView: props.event?.isUsaView }),
          }
          : null,
        bet365Market: bet365Market
          ? {
            ...bet365Market,
            selections: MarketMapper.sortSelections({ selections: bet365Market?.selections, isUSAView: props.event?.isUsaView }),
          }
          : null,
      };
    });

    const displayLine = (selection, feed) => {
      if (!cellValue.value?.[feed]) return '';
      if (isNil(cellValue.value[feed].marketType?.params?.LINE)) return '';
      const line = cellValue.value[feed].marketType.params.LINE;
      if (includes(props.column.id, 'OVER_UNDER')) {
        return `${selection.selectionType.selectionCode === 'OVER' ? 'o' : 'u'} ${line}`;
      }
      let lineValue = '';
      lineValue = line;
      if (selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = `+${line}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = `+${Math.abs(line)}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = `-${line}`;
      }
      return lineValue;
    };

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name);

    const odd = (price) => {
      const americanOdd = price?.originalFormattedValue;
      if (!americanOdd) return '-';
      if (americanOdd && selectedOddFormat.value === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
      if (selectedOddFormat.value === 'probability') {
        const probability = parseFloat((price.probability + price.bias) * 100).toFixed(1);
        return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
      }
      return OddsConverter.getMappedOddValue(price, selectedOddFormat.value);
    };

    return {
      cellValue,
      displayLine,
      odd,
    };
  },
};
</script>
<style lang="scss">
.odds-checker-table-row-cell {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #191414;

  .cell-state-icon {
    height: 100%;
    width: 24px;
    align-items: center;
    display: flex;

    .icon {
      width: 16px;
      height: 16px;

      svg {
        path {
          fill: #CDCDCD;
        }
      }
    }

    &.live {
      .icon {
        svg {
          path {
            fill: #00BC57;
          }
        }
      }
    }
  }

  .odds-checker-cell-label {
    height: 42px;
    display: flex;
    flex-direction: column;

    .odds-checker-cell-label-row {
      &.player-props {
        color: #6D6D6D;
        font-size: 12px;
        margin-top: 1px;
      }
    }
  }

  .column-market-half {
    width: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    position: relative;

    .column-market-suspended {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: #661212;
      background-color: #FFEEEE;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &.left {
      padding: 0 8px;
    }

    &.right {
      padding: 0 8px;
    }

    .column-market-half-selections {
      height: 42px;
      display: flex;
      flex-direction: column;

      &.line {
        color: #6D6D6D;

        .column-market-half-selection {
          text-align: right;
          width: 55px;
        }

        &.handicap {
          .column-market-half-selection {
            width: 40px;
          }
        }
      }

      .column-market-half-selection {
        text-align: right;
        width: 35px;
      }

      .column-market-half-selection-icon {
        width: 25px;
        height: 18px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .icon {
          width: 12px;
          height: 12px;
        }
      }

      .empty-indicator {
        width: 25px;
        height: 18px;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
